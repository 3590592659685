const SuperadminPage  = () => import('@/views/superadmin/superadminpage')
const RolesPage       = () => import('@/views/superadmin/roles')
const TestPage        = () => import('@/views/superadmin/testpage')
const Database        = () => import('@/views/superadmin/database')
const IVS             = () => import('@/views/superadmin/IVS')

const sms             = () => import('@/views/superadmin/sms')
const email           = () => import('@/views/superadmin/email')


 const Routes_SuperAdmin = [{
                              path: "/superadmin",
                              name: "SuperadminPage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/userpage",
                              name: "userpage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },                            
                            }, {
                              path: "/roles",
                              name: "Roles",
                              component: RolesPage,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },   
                            }, {
                              path: "/sms",
                              name: "sms",
                              component: sms,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/email",
                              name: "email",
                              component: email,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },                         
                            }, {
                              path: "/testpage",
                              name: "testpage",
                              component: TestPage,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/Database",
                              name: "Database",
                              component: Database,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/IVS",
                              name: "IVS",
                              component: IVS,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }
];

export default Routes_SuperAdmin;