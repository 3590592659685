
export const WEB_ROOT = process.env.VUE_APP_WEB_ROOT;
export const API_ROOT = process.env.VUE_APP_API_ROOT;
export const AUTH_ROOT = process.env.VUE_APP_AUTH_ROOT;
// export const GRANT_TYPE = "refresh_token";

export const ENCRYPTION_KEY           = process.env.VUE_APP_SECRET || "-";

export const COOKIE_LANGUAGE            = "EN";
export const COOKIE_USER_ID             = "user_id";
export const COOKIE_USER_EMAIL          = "email";
export const COOKIE_USER_NAME           = "name";
export const COOKIE_USER_ACCESSLEVEL    = "accesslevel";
export const COOKIE_USER_REMEMBERME     = "rememberme";
export const COOKIE_LOGIN_REDIRECT      = "loginredirect";
export const COOKIE_REFRESH_INPROGRESS  = "axios_refresh_inprogress";



export const DATE_LOCALE = process.env.VUE_APP_DATE_LOCALE || "en-GB";
export const LANG_LOCALE = process.env.VUE_APP_LANG_LOCALE || "en";
export const LANG_LOCALE_FALLBACK = process.env.VUE_APP_LANG_LOCALE_FALLBACK || "en";
