import { App } from './app';
import { getAuth } from "firebase/auth";

export const Auth = getAuth(App);



import { signInAnonymously, onAuthStateChanged  }     from "firebase/auth";
export const FBsignInAnonymously = async () => {
  try {
    const rtn = await signInAnonymously(Auth);
    // console.info(`FB signInAnonymously:`, rtn);
    return rtn
    
  } catch (err) { 
    console.info(`Error Firebase signInAnonymously:`, err.message); 
    return false
  }
}


export const FBonAuthStateChanged = async (callback) => {
  try {
    onAuthStateChanged(Auth, (user) => {
      return callback(Auth, user);
    })
  } catch (err) { 
    console.info(`Error Firebase FBonAuthStateChanged:`, err.message); 
    return false
  }
}



/*
 * Examples
 *
 * import { FBsignInAnonymously, FBonAuthStateChanged  }    from "@/plugins/firebase/auth";
 * 
    
   FBonAuthStateChanged(this.FBStateChanged)
   FBsignInAnonymously();
   
   methods: {
    FBStateChanged(Auth, user){
      if (!user) {
        console.info(`FIREBASE SIGNED OUT...`);
        this.$store.commit('firebase/setAuthState', null);
        return;
      }
      
      this.$store.commit('firebase/setAuthState', user.uid);
    }
  }

*/