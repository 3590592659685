import axios_api from '@/plugins/axios_api';
// import axios_api from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  FBAUTH_ADDUSER,
  FBREQUEST_UPDATES,
  FBREQUEST_ACTION,
  NUXT_CHECK,
  PUSER_TEST,
} from '@/config/api-url-custom';

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async FBAuthAddUsers ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(FBAUTH_ADDUSER, { fbauth: data});
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error FBAuthAddUsers:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async FBRequestUpdates ( context , data) {    
    try {
      // dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(FBREQUEST_UPDATES, { serverlist: data});
      // console.info("Rtn:", Rtn);
      
      // dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error FBRequestUpdates:", err);   
      
      // dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async FBRequestAction ( context , data) {    
    try {
      // dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(FBREQUEST_ACTION, data);
      // console.info("FBRequestAction Rtn:", Rtn);
      
      // dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error FBRequestAction:", err);   
      
      // dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async TestPusher ( context , data) {    
    try {
      // dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(PUSER_TEST, data, {timeout: 90*1000});
      // console.info("TestPusher Rtn:", Rtn);
      
      // dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data;
    } catch (err) {
      console.info("Error TestPusher:", err);   
      
      // dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async checknuxtserver ( context , data) {    
    try {
      // dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(NUXT_CHECK, {nuxtserverip: data });
      // console.info("checknuxtserver Rtn:", Rtn);
      
      // dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error checknuxtserver:", err);   
      
      // dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};