import axios_auth from '@/plugins/axios_auth';
import router from "@/router/router";

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
    LOGIN_URL,
    LOGOUT_URL,
    REFRESH_TOKEN_URL,
    FORGOT_PASSWORD_URL,
    REGISTER_URL,
    RESET_PASSWORD_CHECK_URL,
    RESET_PASSWORD_CHANGE_URL,
    VERIFY_EMAIL_URL,
} from '@/config/api-url-general';

import Storage from '@/helpers/storage';

import { COOKIE_USER_ID, COOKIE_USER_EMAIL, COOKIE_USER_NAME, 
        COOKIE_USER_ACCESSLEVEL, COOKIE_USER_REMEMBERME } from '@/config/app.js';
        
import { LOGIN_2FA_REQUIRED } from '@/config/status-codes'; // 496

//Errors are handled bij the axios interceptor
//2. 400 bad request Error  --> interceptor .
//2. 401 Auth failed Error  --> interceptor .
//2. 500 etc Error          --> interceptor .


const state = {
  loggedInState : {
    isLoggedIn  : null,
    updateTime  : null, //time last update
    accessLevel : 0,    
    user_id     : null,
    email       : null,
    name        : null, 
  }
};

const getters = {
  getLoggedInState: state => {
    return state.loggedInState;
  },
}

const actions = {
  
  // eslint-disable-next-line no-unused-vars
  async login ({ dispatch, commit }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let login = await axios_auth.post(LOGIN_URL, data);
      
      //Check 2FA required
      if (login.status === LOGIN_2FA_REQUIRED) {
        dispatch("loader/hideLoader", 0, { root: true });
        return '2FA';
      }
      
      //1. Accept, save data      
      let loginData         = login.data.payload;
      loginData.rememberme  = data.rememberme;
      
      commit('setLoggedInState', loginData);
      dispatch("loader/hideLoader", 0, { root: true });
      
      return true;
    } catch (err) {
      console.info("Error Login:", err);   
      commit('setLoggedInState', null);   
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async passwordReset ({ dispatch }, { email }) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let resetPw = await axios_auth.post(FORGOT_PASSWORD_URL, {email: email});
      
      dispatch("loader/hideLoader", 0, { root: true });
      return resetPw.data.msg;
      
    } catch (err) {
      console.info("Error passwordReset:", err);     
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async validateEmailToken ({ dispatch }, { uid, emailtoken}) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let validateEmail= await axios_auth.post(VERIFY_EMAIL_URL, { uid: uid, v: emailtoken} );
      
      dispatch("loader/hideLoader", 0, { root: true });
      return validateEmail.data.msg;
      
    } catch (err) {
      console.info("Error validateEmailToken:", err);     
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async validatePasswordResetToken ({ dispatch }, { uid, resettoken}) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      await axios_auth.post(RESET_PASSWORD_CHECK_URL, { uid: uid, v: resettoken});
      
      dispatch("loader/hideLoader", 0, { root: true });
      return true;
      
    } catch (err) {
      console.info("Error validatePasswordResetToken:", err);     
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async changePassword ({ dispatch }, { uid, resettoken, password}) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let validateResetPw = await axios_auth.put(RESET_PASSWORD_CHANGE_URL, { uid: uid, v: resettoken, password: password});
      
      dispatch("loader/hideLoader", 0, { root: true });
      return validateResetPw.data.msg;
      
    } catch (err) {
      console.info("Error validatePasswordResetToken:", err);     
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  // eslint-disable-next-line no-unused-vars
  async register ({ dispatch, commit }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let register = await axios_auth.post(REGISTER_URL, data);
      
      //1. register accepted
      let registerData         = register.data.payload;
      
      dispatch("loader/hideLoader", 0, { root: true });
      return registerData;
      
    } catch (err) {
      console.info("Error Login:", err);   
      commit('setLoggedInState', null);   
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
  // eslint-disable-next-line no-unused-vars
  async refreshToken ({ commit }) {    
    try {      
      // let refreshToken = Storage.ReadObj(COOKIE_REFRESH_TOKEN, true);
      // if (!refreshToken || typeof(refreshToken) !== "string" || refreshToken.trim() === "") {
      //   throw { msg : 'no refreshToken, clearing..' }
      // }
      let rememberme = Storage.ReadObj(COOKIE_USER_REMEMBERME, true) === true;      
      let refresh = await axios_auth.post(REFRESH_TOKEN_URL, { rememberme: rememberme });
      console.info("refresh:", refresh);
      // return;
      
      //1. Accept, save data      
      let refreshData       = refresh.data.payload;
      refreshData.rememberme  = Storage.ReadObj(COOKIE_USER_REMEMBERME, true) === true;
      commit('setLoggedInState', refreshData);
      
      return true;
      
    } catch (err) {
      
      console.info("Error refresh:", err);   
      commit('setLoggedInState', null);   
      return false;
    }
  },
  
  
  
  
  async logout({ dispatch, commit }) {
    dispatch("loader/showLoader", 15000, { root: true });
    
    // let refreshtoken = Storage.ReadObj(COOKIE_REFRESH_TOKEN, true);
    // if (refreshtoken !== null && refreshtoken !== "") {
    await axios_auth.post(LOGOUT_URL);
    // }    
    
    await commit('setLoggedInState', null );
        
    dispatch("loader/hideLoader", 0, { root: true });
  },
  
  
  updateLoginState({ commit }){
    let loginData           = {};
    // loginData.accessToken   = Storage.ReadObj(COOKIE_ACCESS_TOKEN, true);
    // loginData.refreshToken  = Storage.ReadObj(COOKIE_REFRESH_TOKEN, true);
    loginData.user_id       = Storage.ReadObj(COOKIE_USER_ID, true);
    loginData.email         = Storage.ReadObj(COOKIE_USER_EMAIL, true);
    loginData.name          = Storage.ReadObj(COOKIE_USER_NAME, true);
    loginData.acceslevel    = Storage.ReadObj(COOKIE_USER_ACCESSLEVEL, true, 0);
    loginData.rememberme    = Storage.ReadObj(COOKIE_USER_REMEMBERME, true, false);
    
    return commit('setLoggedInState', loginData);  
  },  
  
};



const mutations = {
  setLoggedInState: (state, loginData) => {
    try {
      
      if (!loginData || loginData === null) {
        throw { msg : 'no loginData, clearing..' }
      } else if (typeof(loginData.acceslevel) === "undefined" || isNaN(parseInt(loginData.acceslevel)) || parseInt(loginData.acceslevel) < 0) {
        throw { msg : 'no access, clearing...' }
      }
      
      state.loggedInState = {
        isLoggedIn  : loginData.user_id > 0,
        updateTime  : new Date().getTime(),
        accessLevel : loginData.acceslevel, 
        user_id     : loginData.user_id,
        email       : loginData.email,
        name        : loginData.name,
      }
      
      const Expiration = loginData.rememberme ? { expireTimes: process.env.VUE_APP_COOKIE_MAXAGE_LONG } : { expireTimes: process.env.VUE_APP_COOKIE_MAXAGE_SHORT };      
      //console.info("Expiration:", Expiration);
      // Storage.StoreObj(COOKIE_ACCESS_TOKEN, loginData.accessToken, true, Expiration);
      // Storage.StoreObj(COOKIE_REFRESH_TOKEN, loginData.refreshToken, true, Expiration);
      Storage.StoreObj(COOKIE_USER_ID, loginData.user_id, true, Expiration);
      Storage.StoreObj(COOKIE_USER_EMAIL, loginData.email, true, Expiration);
      Storage.StoreObj(COOKIE_USER_NAME, loginData.name, true, Expiration);
      Storage.StoreObj(COOKIE_USER_ACCESSLEVEL, loginData.acceslevel, true, Expiration);
      Storage.StoreObj(COOKIE_USER_REMEMBERME, loginData.rememberme, true, Expiration);
      
      return true;
      
    } catch (err) {
      console.info("setLoggedInState err:", err);
      console.info("setLoggedInState err:", loginData);
      
      state.loggedInState = {
        isLoggedIn  : null,
        updateTime  : new Date().getTime(),
        accessLevel : 0, 
        user_id     : null,
        email       : null,
        name        : null,   
      }     
      
      // Storage.Delete(COOKIE_ACCESS_TOKEN);
      // Storage.Delete(COOKIE_REFRESH_TOKEN);
      Storage.Delete(COOKIE_USER_ID);
      Storage.Delete(COOKIE_USER_EMAIL);
      Storage.Delete(COOKIE_USER_NAME);
      Storage.Delete(COOKIE_USER_ACCESSLEVEL);   
      Storage.Delete(COOKIE_USER_REMEMBERME);
      
      router.push({name: 'Login'}).catch(()=>{});
    }
  },
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};