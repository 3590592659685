import axios_api from '@/plugins/axios_api';
// import axios_api from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  IVS_GET_STREAMS,
  IVS_CREATE_STREAM,
  IVS_DELETE_STREAM,
} from '@/config/api-url-custom';


const state = {
  
};

const getters = {
 
}

const actions = {
  
  async IVS_Get_Channels ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(IVS_GET_STREAMS, { });
      console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error IVS_Get_Channels:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async IVS_Delete_Channel ({ dispatch }, channelarn) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(IVS_DELETE_STREAM, { channelarn: channelarn });
      console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error IVS_Delete_Channel:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async IVS_Create_Channel ({ dispatch }, channelname) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(IVS_CREATE_STREAM, { channelname: channelname });
      console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error IVS_Create_Channel:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};