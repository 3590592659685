const defaultset = {
  ...require('./default/_master'), 
  ...require('./default/extra')
};

const nl = {
  ...defaultset,
  ...require('./nl/_master'), 
  ...require('./nl/extra')
};

const LanguageSets = {
  defaultset,
  nl,
};

module.exports = LanguageSets;