
// import UserPage         from "@/views/user/userpage.vue";
import Profile          from "@/views/user/profile.vue";
const Streams         = () => import('@/views/superadmin/streams')
const UserStats       = () => import('@/views/superadmin/UserStats')
// const VideoEditor     = () => import('@/views/superadmin/video_editor')
const userStatsTool   = () => import('@/views/superadmin/UserStatsTool')



 const Routes_User = [
   {
  //                             path: "/userpage",
  //                             name: "userpage",
  //                             component: UserPage,
  //                             meta: { userNav: true }
  //                           }, {
                              path: "/profile",
                              name: "profile",
                              component: Profile,
                              meta: { userNav: true }
                            }, {
                              path: "/Streams",
                              name: "Streams",
                              component: Streams,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/UserStats",
                              name: "UserStats",
                              component: UserStats,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            }, {
                              path: "/UserStatsTool",
                              name: "UserStatsTool",
                              component: userStatsTool,
                              // meta: { superadminNav: true },
                              meta: { userNav: true, hideFooter : true  },
                            // }, {
                            //   path: "/VideoEditor",
                            //   name: "VideoEditor",
                            //   component: VideoEditor,
                            //   // meta: { superadminNav: true },
                            //   meta: { userNav: true, hideFooter : true  },
                            }
                            
];

export default Routes_User;