import Routes_Public          from '@/router/routes/public.js';      //Public pages
import Routes_User            from '@/router/routes/user.js';        //SuperAdmin Pages
import Routes_SuperAdmin      from '@/router/routes/superadmin.js';  //SuperAdmin Pages

const Routes_Default = [ { path: '*', redirect: '/' } ]; // otherwise redirect to home

/*
 * Set Minimal Access Level to view the page
 */
Routes_Public.forEach((item)      => {item.meta.MinAccLevel = 0});
Routes_User.forEach((item)        => {item.meta.MinAccLevel = 1});
Routes_SuperAdmin.forEach((item)  => {item.meta.MinAccLevel = 10});

const Routes = [ 
    ...Routes_Public, 
    ...Routes_User, 
    ...Routes_SuperAdmin, 
    ...Routes_Default 
];

export default Routes;