import axios_api from '@/plugins/axios_api';
// import axios_api from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  DB_USER_STATS,
  DB_STREAM_DATA,
  DB_ROLE_DATA,
  DB_STRUCTURE,
  DB_FORMAT_QUERY,
  DB_RUN_QUERY,
  DB_GET_LOCKSTATUS,
  DB_SET_LOCKSTATUS,
  DB_CLEAR_LOCK,
} from '@/config/api-url-custom';

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async DB_GetUserStats ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(DB_USER_STATS, { });
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_GetUserStats:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async DB_GetStreamData ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(DB_STREAM_DATA, { });
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data;
    } catch (err) {
      console.info("Error DB_GetStreamData:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async DB_GetRoleData ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(DB_ROLE_DATA, { });
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload.roleData;
    } catch (err) {
      console.info("Error DB_GetRoleData:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
  
  
  
  async DB_GetLockStatus ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(DB_GET_LOCKSTATUS, { });
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_GetLockStatus:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async DB_SetLockStatus ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(DB_SET_LOCKSTATUS, data);
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_SetLockStatus:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async DB_ClearLockStatus ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(DB_CLEAR_LOCK, { });
      console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_ClearLockStatus:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async DB_GetDBStructure ({ dispatch }) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.get(DB_STRUCTURE, { });
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_GetDBStructure:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async DB_FormatQuery ({ dispatch }, Query) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(DB_FORMAT_QUERY, {query: Query});
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_FormatQuery:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  async DB_RunQuery ({ dispatch }, Query) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(DB_RUN_QUERY, {query: Query});
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error DB_RunQuery:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};