import Vue              from 'vue';
import Vuex             from 'vuex';

import loader           from '@/store/modules/general/loader';
import dialog           from '@/store/modules/general/dialog';
import authentication   from '@/store/modules/general/authentication';
import twofa            from '@/store/modules/general/twofa';
import contactus        from '@/store/modules/general/contactus';
import notifications    from '@/store/modules/general/notifications';
import firebase         from '@/store/modules/general/firebase';

import test from '@/store/modules/test';

/* monitor */
import monitor from '@/store/modules/monitor/monitorservers';
import databaseactions from '@/store/modules/monitor/databaseactions';
import ivs_actions from '@/store/modules/monitor/ivs_actions';
import video_editor from '@/store/modules/monitor/video_editor';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loader,
    dialog,
    
    authentication,
    twofa,
    contactus,
    notifications,
    firebase,
    
    monitor,
    databaseactions,
    ivs_actions,
    video_editor,
    
    test,    
  },
});
