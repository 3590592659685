import axios_api from '@/plugins/axios_api';
import axios_auth from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
    GET_TEST_DATA,
    GET_TEST2_DATA,
    GET_TESTJWT_DATA,
} from '@/config/api-url-custom';
        

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async getTestData ({ dispatch }, data) {
    
    try {      
      // dispatch("loader/showLoader", 15000, { root: true });
      
      let testdata = await axios_api.get(GET_TEST_DATA, data);
      // console.info("testdata:", testdata);
      
      // dispatch("loader/hideLoader", 0, { root: true });
      
      return testdata;
    } catch (err) {
      console.info("Error Login:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async getTestFeed ({ dispatch }, data) {
    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      
      let testdata = await axios_api.get(GET_TEST2_DATA, data);
      console.info("testdata:", testdata);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return testdata.data.payload;
    } catch (err) {
      console.info("Error Login:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async getTestJWTData ({ dispatch }, data) {
    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      
      let testdata = await axios_auth.get(GET_TESTJWT_DATA, data);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return testdata.data.payload;
    } catch (err) {
      console.info("Error Login:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};