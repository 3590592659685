/* profile */
export const GET_PROFILES     = '/tweet/profiles/get';
export const UPDATE_PROFILES  = '/tweet/profiles/update';
export const ADD_PROFILE      = '/tweet/profiles/create';

/* tweet post */
export const CREATE_POST      = '/tweet/post/create';

/* admin testfunction */
export const GET_TEST_DATA    = '/admin/testfunction';

/* SERVICE SERVER test */
export const GET_TEST2_DATA   = '/testfeed/getdata';
export const GET_TESTJWT_DATA = '/testing/get_jwt_data';


/* MONITOR SERVERS */
export const FBAUTH_ADDUSER   = '/monitor/FBAuth/adduser';
export const FBREQUEST_UPDATES = '/monitor/FBAuth/activateupdates';
export const FBREQUEST_ACTION = '/monitor/FBAuth/action';
export const NUXT_CHECK       = '/monitor/nuxt/checkserver';
export const PUSER_TEST       = '/monitor/nuxt/testpusher';

export const DB_USER_STATS    = '/userstats/database/GetUsersStats';

export const DB_STREAM_DATA   = '/monitor/database/GetStreamData';
export const DB_ROLE_DATA     = '/monitor/database/GetRoleData';
export const DB_STRUCTURE     = '/monitor/database/GetDBStructure';
export const DB_FORMAT_QUERY  = '/monitor/database/FormatQuery';
export const DB_RUN_QUERY     = '/monitor/database/RunQuery';

export const DB_GET_LOCKSTATUS  = '/monitor/database/GetlockStatus';
export const DB_SET_LOCKSTATUS  = '/monitor/database/SetlockStatus';
export const DB_CLEAR_LOCK      = '/monitor/database/clearUnLock';


export const IVS_GET_STREAMS    = '/monitor/ivs/getStreams';
export const IVS_CREATE_STREAM  = '/monitor/ivs/createChannel';
export const IVS_DELETE_STREAM  = '/monitor/ivs/deleteChannel';

export const VIDEO_EDITOR_DATA          = '/videoEditor/ScanS3BucketPath';
export const VIDEO_EDITOR_MAKE_PUBLIC   = '/videoEditor/MakeS3BucketPathPublic';
export const VIDEO_EDITOR_CREATE_VIDEO  = '/videoEditor/DownloadVideo';










