import axios_api from '@/plugins/axios_api';
// import axios_api from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  VIDEO_EDITOR_DATA,
  VIDEO_EDITOR_MAKE_PUBLIC,
  VIDEO_EDITOR_CREATE_VIDEO
} from '@/config/api-url-custom';


const state = {
  
};

const getters = {
 
}

const actions = {
  
  async getData ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(VIDEO_EDITOR_DATA, data);
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error video_editor getdata:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async makePathPublic ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let Rtn = await axios_api.post(VIDEO_EDITOR_MAKE_PUBLIC, data);
      // console.info("Rtn:", Rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return Rtn.data.payload;
    } catch (err) {
      console.info("Error video_editor makePathPublic:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  async setData ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      // console.info(`data:`, data);
      let rtn = await axios_api.post(VIDEO_EDITOR_CREATE_VIDEO, data);
      // console.info("Rtn:::", rtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return rtn.data.payload;
    } catch (err) {
      console.info("Error video_editor setData:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};