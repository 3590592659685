<template>
    <section>
      
      <div class="columns m1rem">
        
        <div v-if="viewMode === 'open'" class="column">
          <Card :cardTitle="$wl('setup_2fa_add')" :cardIcon="'domain'" >                  
            <ButtonsForm :confirmLabel="$wl('setup_2fa_add')" class="mt2rem" @ConfirmBtnClicked="Setup2FA"></ButtonsForm>          
          </Card>
        </div>
        
         <div v-if="viewMode === 'setup'" class="column">
            <Card :cardTitle="$wl('setup_2fa_confirm')" :cardIcon="'domain'" >            
              <img :src="QRImg" alt="QR image" key=""/>
              <b-input type="text" disabled v-model="secret" rounded ></b-input>
              
              <b-field :label="$wl('setup_2fa_code_input')">
                  <b-input :placeholder="$wl('setup_2fa_code_input')" v-model="frmdata.twoFA" type="number" rounded ></b-input>
              </b-field>
              
              <ButtonsForm :confirmLabel="$wl('setup_2fa_confirm_btn')" class="mt2rem" @ConfirmBtnClicked="Verify2FA"></ButtonsForm>          
            </Card>
         </div>
          
          <div v-if="viewMode === 'active'" class="column">
            <Card :cardTitle="$wl('setup_2fa_remove')" :cardIcon="'domain'" >            
              
              <b-field :label="$wl('setup_2fa_code_input')">
                  <b-input :placeholder="$wl('setup_2fa_code_input')" v-model="frmdata.twoFA" type="number" rounded ></b-input>
              </b-field>
                       
              <ButtonsForm :confirmLabel="$wl('setup_2fa_remove_btn')" class="mt2rem" @ConfirmBtnClicked="Remove2FA"></ButtonsForm>          
            </Card>
          </div>
          
      </div>
      
    </section>
</template>

<script>


import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";

export default {
    name: "AccountTwofactor",
    
    components: {
      Card,
      ButtonsForm,
    },
    
    data() {
      return {
        viewMode: 'open',  //open//setup/active'
        secret  : '',
        QRImg   : '',
        frmdata : {
          twoFA : '',
        }
      }
    },
    
    mounted(){
      this.getStatus();
    },
    
    methods: { 
      async setPageData(data) {
        if (data === false) return;
        
        let { status = null, secret = null } = data;
        this.viewMode = status;
        this.secret = secret  === null ? '' : secret.code;
        this.QRImg  = secret  === null ? '' : secret.qr; 
        
        this.frmdata.twoFA = '';       
      },
      
      async getStatus(){
        let rtn = await this.$store.dispatch('twofa/getStatus', null);   
        this.setPageData(rtn)
      },
      
      async Setup2FA(){
        let rtn = await this.$store.dispatch('twofa/setup', null);   
        this.setPageData(rtn);
      },
      
      async Verify2FA(){
        let rtn = await this.$store.dispatch('twofa/verify', this.frmdata);  
        this.setPageData(rtn);
      },
      
      async Remove2FA(){
        console.info("Remove2FA...");
        let rtn = await this.$store.dispatch('twofa/remove', this.frmdata);  
        this.setPageData(rtn);
      },
      
    }
};
</script>

<style scoped>


    
</style>
