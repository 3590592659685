<template>
    <section>
      
      
      <div class="columns m1rem">
        
        <div class="column">
          <Card :cardTitle="'Wallet'" :cardIcon="'domain'" >       
            
            <b-field :label="'Name'">
                <b-input :placeholder="'Name'" v-model="frmdata.profile_name" type="text" rounded ></b-input>
            </b-field>
            
            <b-field :label="'Desciption'">
                <b-input :placeholder="'Desciption'" v-model="frmdata.description" type="text" rounded ></b-input>
            </b-field>
            
            <ButtonsForm :confirmLabel="'Save Wallet'" class="mt2rem" @ConfirmBtnClicked="SaveWallet"></ButtonsForm>      
            
            
            <!-- <QrcodeVue :value="wallet_receive_address" :size="QRsize" level="H" /> -->
            <b-field :label="'Top up address'">
              <img class="box" :src="QRRecieveAddress" alt="Top up address" />
            </b-field>
            <b-field>
              <div class="box" > {{ wallet_receive_address }} </div>
            </b-field>
                
          </Card>         
          
        </div>
          
      </div>
      
    </section>
</template>

<script>

import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";

// import QRCode from 'qrcode'

export default {
    name: "tweetprofile",
    
    props: ['tweetprofile'],
    
    components: {
      Card,
      ButtonsForm,
    },
    
    data() {
      return {
        frmdata : {
          user_id         : null,
          profile_id      : null,
          wallet_id       : null,
          wallet_key      : null,       
             
          profile_name    : '',
          description     : '',
          picture_url     : '',
          profile_status  : '',
        },
        
        QRRecieveAddress        : '',
        QRsize                  : 300,
        
        wallet_receive_address  : '',
        wallet_xpubMain         : '',
      }
    },
    
    async mounted(){
      this.wallet_receive_address    = this.tweetprofile.wallet_receive_address;
      this.wallet_xpubMain           = this.tweetprofile.wallet_xpubMain;
      this.QRRecieveAddress          = await this.GetQR(this.wallet_receive_address);
      
        
      this.frmdata = {
        user_id         : this.tweetprofile.user_id,
        profile_id      : this.tweetprofile.profile_id,
        wallet_id       : this.tweetprofile.wallet_id,
        wallet_key      : this.tweetprofile.wallet_key,
        
        profile_name    : this.tweetprofile.profile_name,
        description     : this.tweetprofile.description,
        picture_url     : this.tweetprofile.picture_url,
        profile_status  : this.tweetprofile.profile_status,
      }
    },
    
    methods: { 

      async GetQR(value, options = { quality: '0.8', errorCorrectionLevel: 'H' }){
        try {
          console.info("value:", value);
          console.info("options:", options);
          return ""; //await QRCode.toDataURL(value, options)
        } catch (err) {
          console.error(err);
          return "";
        }
      },
      
      async SaveWallet(){
        await this.$store.dispatch('tweetprofiles/set', this.frmdata);
      },
    }
};
</script>

<style scoped>


    
</style>
